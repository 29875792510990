import React from 'react'
import { Link } from 'react-router-dom'
import './style.css'
import { Button } from '@mui/material'
import { format } from 'date-fns'

export const WriteEmailButton: React.FC<{
  sId?: string | null
  gwSid?: string | null
  ca?: any
}> = ({ sId, gwSid, ca }) => {
  return (
    <Button
      sx={{ mt: 1 }}
      variant="contained"
      component={Link}
      to={`mailto:support@arcletic.com?subject=${encodeURIComponent(
        `Request about Subscription ${sId}`
      )}&body=${encodeURIComponent(
        `Dear Arcletic Team, \n\nI have a request about my subscription. \n\nsubscriptionId: ${
          sId || ''
        }\nGatewayId: ${gwSid || ''}\nPurchased At: ${
          !ca || isNaN(ca)
            ? ca || ''
            : format(new Date(parseInt(ca)), 'yyyy-mm-dd HH:mm')
        }\n\nMy Request: \n`
      )}`}
    >
      Write an email
    </Button>
  )
}

/* <Link className="mp-link" to="mailto:support@arcletic.com">
      Write us a E-Mail
    </Link> */
