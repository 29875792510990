import { Cancel } from '@mui/icons-material'
import { Box, Button, Container, Stack } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { format } from 'date-fns'
import { useConfirm } from 'material-ui-confirm'
import React from 'react'
import { GatewayType, WaSubscriptionFragment } from '../../graphql/generated'
import './style.css'
import { WriteEmailButton } from './WriteEmailButton'

export const SubscriptionTable: React.FC<{
  subscription: WaSubscriptionFragment
  cancelSubscriptionRequest: () => void
}> = ({ subscription, cancelSubscriptionRequest }) => {
  const confirm = useConfirm()
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'type', headerName: 'Type', flex: 1 },
    { field: 'startDate', headerName: 'Date', flex: 1 },
    { field: 'endDate', headerName: 'Vaild Until', flex: 1 },
    { field: 'amount', headerName: 'Amount', flex: 1 },
  ]

  const rows = (subscription?.transactions || []).map((t) => {
    const refundAmount = (t.refund || []).reduce(
      (a, b) => a + (b?.value || 0),
      0
    )
    return {
      id: t._id,
      type: t.isTrial ? 'Trial' : 'Transaction',
      startDate: format(new Date(t.startDate), 'yyyy-mm-dd HH:mm'),
      endDate: format(new Date(t.endDate), 'yyyy-mm-dd HH:mm'),
      amount: [
        t.payment?.currency,
        t.payment?.priceGross,
        ...[
          refundAmount
            ? [
              ' - refunded: ' + refundAmount,
              '= ' + ((t.payment?.priceGross || 0) - refundAmount),
            ]
            : [],
        ],
      ].join(' '),
    }
  })

  const cancelSubscriptionClick = async () => {
    confirm({
      description: (
        <>
          Are you sure you want to cancel this subscription? <br />
          You will lose access immediately.
        </>
      ),
      cancellationText: 'No',
      confirmationButtonProps: { color: 'error' },
      confirmationText: 'Cancel Subscription',
    })
      .then(() => {
        cancelSubscriptionRequest()
      })
      .catch(() => {})
  }

  const totalSum = (subscription?.transactions || []).reduce((acc, curr) => {
    return acc + (curr?.payment?.priceGross || 0)
  }, 0)

  return (
    <Container>
      <h2>Your Purchase</h2>
      <Stack direction="row" spacing={2}>
        <Stack direction="column" spacing={2} flex={1}>
          <Stack direction="row" spacing={2}>
            <Box>
              Purchased:{' '}
              {format(new Date(subscription.startDate), 'yyyy-mm-dd HH:mm')}
            </Box>
          </Stack>
          {/* <Stack direction="row" spacing={2}>
            <Box>Status: {subscription.status}</Box>
          </Stack> */}

          <Stack direction="row" spacing={2}>
            <Box>Gateway: {subscription.gateway}</Box>
          </Stack>
          <Stack direction="row" spacing={2}>
            {subscription.isRecurring && (
              <Box>
                Status: {subscription.nextRenew ? 'Active' : 'Cancelled'}
              </Box>
            )}
          </Stack>
        </Stack>

        <Stack direction="column" spacing={2} flex={1}></Stack>

        {!!subscription.isRecurring &&
          !!subscription.nextRenew &&
          subscription.gateway === GatewayType.STRIPE && (
          <Stack
            direction="column"
            spacing={2}
            flex={1}
            sx={{ maxWidth: 300 }}
          >
            <Button
              variant="contained"
              startIcon={<Cancel />}
              onClick={cancelSubscriptionClick}
            >
                Cancel Subscription
            </Button>

            {(!!subscription.isTrial || !totalSum) && (
              <p>
                  When cancelling during the trial period, you will lose access
                  immediately.
              </p>
            )}
          </Stack>
        )}
      </Stack>
      <Box mt={20} />

      {subscription.transactions?.length && (
        <Box>
          <h2>Transactions</h2>
          <DataGrid
            rows={rows}
            columns={columns}
            style={{ color: 'white' }}
            hideFooter={true}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false, // Hide column id
                },
              },
            }}
          />
        </Box>
      )}

      <Box className="mp-supportbox">
        <p>
          If you have any further requests, please contact us at
          support@arcletic.com
        </p>
        <WriteEmailButton
          sId={subscription._id}
          gwSid={subscription.gatewaySubscriptionId}
          ca={subscription.createdAt}
        />
      </Box>
    </Container>
  )
}
