import { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import {
  GatewayType,
  useCancelSubscriptionMutation,
  useGetSubscriptionWTxnsLazyQuery,
} from '../../graphql/generated'
import './style.css'
import { SubscriptionTable } from './SubscriptionTable'
import { Box } from '@mui/material'
import { WriteEmailButton } from './WriteEmailButton'

export type RouteParams = {
  subscriptionId?: string
}

function useQuery () {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

const useQuery2 = () => {
  const query = useQuery()
  return {
    // txnId: query.get('txnId'),
    gateway: query.get('gwt') as GatewayType,
    gatewaySubscriptionId: query.get('gwi'),
    createdAt: query.get('ca'), // can be passed as timestamp or date string
    source: query.get('fs') || null,
  }
}

export function ManagePurchase () {
  const { subscriptionId } = useParams<RouteParams>()
  const { gateway, gatewaySubscriptionId, createdAt, source } = useQuery2()
  const [getSubscription, { loading: loadingQuery, error: errorQuery, data }] =
    useGetSubscriptionWTxnsLazyQuery()
  // const { data: data2 } = useIsLoggedInQuery()
  const [
    cancelSubscription,
    { loading: loadingMutation, error: errorMutation },
  ] = useCancelSubscriptionMutation()
  const [isRendered, setIsRendered] = useState(false)
  // const [loadingMutation, setLoadingMutation] = useState(false)
  // const [setCustomError, setLoadingMutation] = useState(false)

  const loading = loadingQuery || loadingMutation || !isRendered
  const error = errorQuery || errorMutation

  const inputValid =
    !!subscriptionId &&
    !!gateway &&
    Object.values(GatewayType).includes(gateway) &&
    !!gatewaySubscriptionId &&
    !!createdAt

  useEffect(() => {
    setIsRendered(true)
    // console.log('input valid', inputValid)
    if (inputValid) {
      fetchSubscription()
    }
  }, [subscriptionId, gateway, gatewaySubscriptionId])

  const getRequestInput = () => {
    return {
      subscriptionId,
      gateway,
      gatewaySubscriptionId,
      createdAt: createdAt
        ? new Date(isNaN(createdAt as any) ? createdAt : parseInt(createdAt))
        : null,
      source,
    }
  }
  const fetchSubscription = (noCache = false) => {
    const input = getRequestInput()
    console.log('fetchSubscription', input)
    getSubscription({
      variables: { input: input },
      fetchPolicy: noCache ? 'network-only' : 'cache-first',
    })
  }

  const cancelSubscriptionRequest = async () => {
    try {
      const input = getRequestInput()
      const { data } = await cancelSubscription({ variables: { input: input } })
      if (data?.cancelSubscription) {
        fetchSubscription(true)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  if (error) {
    console.log('error', error)
  }

  const subscription = data?.getSubscriptionWTxns?.[0]

  return (
    <div className="mp-container">
      <main className="mp-main">
        {!inputValid && (
          <Box className="mp-supportbox mp-contentminbox">
            <p>
              Sorry, the link you have followed is invalid. Please write us at
              support@arcletic.com or reply directly to the email we have sent
              you. <br />
              <br />
              We are sorry for the inconvenience!
            </p>
            <WriteEmailButton
              sId={subscriptionId}
              gwSid={gatewaySubscriptionId}
              ca={createdAt}
            />
          </Box>
        )}
        {loading && (
          <Box className="mp-supportbox mp-contentminbox">
            <p>Loading...</p>
          </Box>
        )}
        {(error || (inputValid && !loading && !subscription)) && (
          <Box className="mp-supportbox mp-contentminbox">
            <p>
              Sorry, an error occured: &quot;{error?.message || 'not found'}
              &quot;
              <br />
              <br />
              Please write us at support@arcletic.com or reply directly to the
              email we have sent you.
              <br />
              <br />
              We are sorry for the inconvenience!
            </p>
            <WriteEmailButton
              sId={subscriptionId}
              gwSid={gatewaySubscriptionId}
              ca={createdAt}
            />
          </Box>
        )}

        {!loading && !!subscription && (
          <SubscriptionTable
            subscription={subscription}
            cancelSubscriptionRequest={cancelSubscriptionRequest}
          />
        )}
      </main>
    </div>
  )
}
