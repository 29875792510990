import './style.css'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'

export function HomePage () {
  return (
    <div className="home-container">
      <main className="home-main">
        <h1>Arcletic</h1>
        <Button
          href="https://applinks.arcletic.com/download"
          variant="contained"
        >
          Download now
        </Button>

        <Link
          to="/purchases/manage/1234/?tx=123a&source=GS1FREE"
          className="home-devlink"
        >
          Manage your purchase
        </Link>
      </main>
    </div>
  )
}
