import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Mongo object id scalar type */
  ObjectId: any;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type Query = {
  __typename?: 'Query';
  user?: Maybe<User>;
  isLoggedIn: Scalars['Boolean'];
  getUsers: UserListRespDto;
  userAdmin?: Maybe<User>;
  getCompanies: CompaniesHistoryRespDto;
  getCompanyMembers: CompanyMembersRespDto;
  getcourses: CourseListRespDto;
  courses: Array<Course>;
  getGames: GameListRespDto;
  getGameAuthors: GameAuthorsListRespDto;
  getDiscoverContent: DiscoverDto;
  userGameHistories: Array<GameHistory>;
  allBenefits: Array<BenefitDto>;
  getDiscoverTemplate: Scalars['String'];
  getAllLicenses: LicensHistoryRespDto;
  getLicensesByUserId: Array<License>;
  getLicensesByCompanyId: Array<License>;
  getProducts: Array<Product>;
  getPaginatedProducts: ProductHistoryRespDto;
  getPruductById: Product;
  getExclusiveProducts: Array<Product>;
  getSportsByUserId: Array<Sport>;
  getSports: SportHistoryRespDto;
  getMasterClasses: Array<MasterClass>;
  getSubscriptions: SubscriptionHistoryRespDto;
  getSubscription: Subscription;
  getSubscriptionWTxns: Array<Subscription>;
  getTransactions: TransactionHistoryRespDto;
};


export type QueryGetUsersArgs = {
  input: FilterParamsInput;
};


export type QueryUserAdminArgs = {
  userId: Scalars['ObjectId'];
};


export type QueryGetCompaniesArgs = {
  input: FilterParamsUserInput;
};


export type QueryGetCompanyMembersArgs = {
  input: CompanyMembersInput;
};


export type QueryGetcoursesArgs = {
  input: FilterParamsInput;
};


export type QueryGetGamesArgs = {
  input: FilterParamsInput;
};


export type QueryGetGameAuthorsArgs = {
  input: FilterParamsInput;
};


export type QueryGetAllLicensesArgs = {
  input: FilterParamsUserInput;
};


export type QueryGetLicensesByCompanyIdArgs = {
  input: GetCompanyLicenseInput;
};


export type QueryGetPaginatedProductsArgs = {
  input: FilterParamsInput;
};


export type QueryGetPruductByIdArgs = {
  input: GetProductInput;
};


export type QueryGetExclusiveProductsArgs = {
  input: GetExclusiveProductsInput;
};


export type QueryGetSportsArgs = {
  input: FilterParamsInput;
};


export type QueryGetSubscriptionsArgs = {
  input: FilterParamsUserInput;
};


export type QueryGetSubscriptionArgs = {
  input: ManageSubscriptionInput;
};


export type QueryGetSubscriptionWTxnsArgs = {
  input: ManageSubscriptionInput;
};


export type QueryGetTransactionsArgs = {
  input: FilterParamsUserInput;
};

export type User = {
  __typename?: 'User';
  _id: Scalars['ObjectId'];
  role: UserRoles;
  emails?: Maybe<Array<EmailAddress>>;
  firstName?: Maybe<Scalars['String']>;
  played: Array<Played>;
  invitationCode?: Maybe<Scalars['String']>;
  /** sent invites */
  invited?: Maybe<Array<Invited>>;
  /** received requests */
  receivedFriendRequests?: Maybe<Array<User>>;
  friends?: Maybe<Array<Friend>>;
  country?: Maybe<Countries>;
  language?: Maybe<Language>;
  avatar?: Maybe<Avatar>;
  username?: Maybe<Scalars['String']>;
  googleId: Scalars['String'];
  appleId: Scalars['String'];
  giveAccess?: Maybe<Scalars['Boolean']>;
  deviceInfo?: Maybe<DeviceInfo>;
  gameReminders?: Maybe<Array<GameReminder>>;
  playedSums?: Maybe<Array<PlayedSum>>;
  playedCount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** deprecated, use "username" instead */
  userName?: Maybe<Scalars['String']>;
};

export enum UserRoles {
  PLAYER = 'PLAYER',
  ADMIN = 'ADMIN'
}

export type EmailAddress = {
  __typename?: 'EmailAddress';
  email: Scalars['String'];
  /** if null: unconfirmed */
  confirmedDate?: Maybe<Scalars['DateTime']>;
};

export type Played = {
  __typename?: 'Played';
  startedDate: Scalars['DateTime'];
  notification?: Maybe<Array<Notification>>;
  audioId: Scalars['ObjectId'];
  playlistType: PlaylistType;
  playlistId: Scalars['ObjectId'];
  playedTo: Scalars['Float'];
  finishedDate?: Maybe<Scalars['DateTime']>;
};

export type Notification = {
  __typename?: 'Notification';
  token: Scalars['String'];
  provider: Provider;
  updateAt: Scalars['DateTime'];
};

export enum Provider {
  APPLE = 'APPLE',
  GOOGLE = 'GOOGLE'
}

export enum PlaylistType {
  COURSE = 'COURSE',
  MYEXERCISE = 'MYEXERCISE'
}

export type Invited = {
  __typename?: 'Invited';
  email: Scalars['String'];
  date: Scalars['DateTime'];
};

export type Friend = {
  __typename?: 'Friend';
  user: User;
  date: Scalars['DateTime'];
};

export enum Countries {
  AF = 'AF',
  AX = 'AX',
  AL = 'AL',
  DZ = 'DZ',
  AS = 'AS',
  AD = 'AD',
  AO = 'AO',
  AI = 'AI',
  AQ = 'AQ',
  AG = 'AG',
  AR = 'AR',
  AM = 'AM',
  AW = 'AW',
  AU = 'AU',
  AT = 'AT',
  AZ = 'AZ',
  BS = 'BS',
  BH = 'BH',
  BD = 'BD',
  BB = 'BB',
  BY = 'BY',
  BE = 'BE',
  BZ = 'BZ',
  BJ = 'BJ',
  BM = 'BM',
  BT = 'BT',
  BO = 'BO',
  BA = 'BA',
  BW = 'BW',
  BV = 'BV',
  BR = 'BR',
  IO = 'IO',
  BN = 'BN',
  BG = 'BG',
  BF = 'BF',
  BI = 'BI',
  KH = 'KH',
  CM = 'CM',
  CA = 'CA',
  CV = 'CV',
  KY = 'KY',
  CF = 'CF',
  TD = 'TD',
  CL = 'CL',
  CN = 'CN',
  CX = 'CX',
  CC = 'CC',
  CO = 'CO',
  KM = 'KM',
  CG = 'CG',
  CD = 'CD',
  CK = 'CK',
  CR = 'CR',
  CI = 'CI',
  HR = 'HR',
  CU = 'CU',
  CY = 'CY',
  CZ = 'CZ',
  DK = 'DK',
  DJ = 'DJ',
  DM = 'DM',
  DO = 'DO',
  EC = 'EC',
  EG = 'EG',
  SV = 'SV',
  GQ = 'GQ',
  ER = 'ER',
  EE = 'EE',
  ET = 'ET',
  FK = 'FK',
  FO = 'FO',
  FJ = 'FJ',
  FI = 'FI',
  FR = 'FR',
  GF = 'GF',
  PF = 'PF',
  TF = 'TF',
  GA = 'GA',
  GM = 'GM',
  GE = 'GE',
  DE = 'DE',
  GH = 'GH',
  GI = 'GI',
  GR = 'GR',
  GL = 'GL',
  GD = 'GD',
  GP = 'GP',
  GU = 'GU',
  GT = 'GT',
  GG = 'GG',
  GN = 'GN',
  GW = 'GW',
  GY = 'GY',
  HT = 'HT',
  HM = 'HM',
  VA = 'VA',
  HN = 'HN',
  HK = 'HK',
  HU = 'HU',
  IS = 'IS',
  IN = 'IN',
  ID = 'ID',
  IR = 'IR',
  IQ = 'IQ',
  IE = 'IE',
  IM = 'IM',
  IL = 'IL',
  IT = 'IT',
  JM = 'JM',
  JP = 'JP',
  JE = 'JE',
  JO = 'JO',
  KZ = 'KZ',
  KE = 'KE',
  KI = 'KI',
  KP = 'KP',
  KR = 'KR',
  XK = 'XK',
  KW = 'KW',
  KG = 'KG',
  LA = 'LA',
  LV = 'LV',
  LB = 'LB',
  LS = 'LS',
  LR = 'LR',
  LY = 'LY',
  LI = 'LI',
  LT = 'LT',
  LU = 'LU',
  MO = 'MO',
  MK = 'MK',
  MG = 'MG',
  MW = 'MW',
  MY = 'MY',
  MV = 'MV',
  ML = 'ML',
  MT = 'MT',
  MH = 'MH',
  MQ = 'MQ',
  MR = 'MR',
  MU = 'MU',
  YT = 'YT',
  MX = 'MX',
  FM = 'FM',
  MD = 'MD',
  MC = 'MC',
  MN = 'MN',
  MS = 'MS',
  MA = 'MA',
  MZ = 'MZ',
  MM = 'MM',
  NA = 'NA',
  NR = 'NR',
  NP = 'NP',
  NL = 'NL',
  AN = 'AN',
  NC = 'NC',
  NZ = 'NZ',
  NI = 'NI',
  NE = 'NE',
  NG = 'NG',
  NU = 'NU',
  NF = 'NF',
  MP = 'MP',
  NO = 'NO',
  OM = 'OM',
  PK = 'PK',
  PW = 'PW',
  PS = 'PS',
  PA = 'PA',
  PG = 'PG',
  PY = 'PY',
  PE = 'PE',
  PH = 'PH',
  PN = 'PN',
  PL = 'PL',
  PT = 'PT',
  PR = 'PR',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RU = 'RU',
  RW = 'RW',
  SH = 'SH',
  KN = 'KN',
  LC = 'LC',
  PM = 'PM',
  VC = 'VC',
  WS = 'WS',
  SM = 'SM',
  ST = 'ST',
  SA = 'SA',
  SN = 'SN',
  RS = 'RS',
  ME = 'ME',
  SC = 'SC',
  SL = 'SL',
  SG = 'SG',
  SK = 'SK',
  SI = 'SI',
  SB = 'SB',
  SO = 'SO',
  ZA = 'ZA',
  GS = 'GS',
  ES = 'ES',
  LK = 'LK',
  SD = 'SD',
  SR = 'SR',
  SJ = 'SJ',
  SZ = 'SZ',
  SE = 'SE',
  CH = 'CH',
  SY = 'SY',
  TW = 'TW',
  TJ = 'TJ',
  TZ = 'TZ',
  TH = 'TH',
  TL = 'TL',
  TG = 'TG',
  TK = 'TK',
  TO = 'TO',
  TT = 'TT',
  TN = 'TN',
  TR = 'TR',
  TM = 'TM',
  TC = 'TC',
  TV = 'TV',
  UG = 'UG',
  UA = 'UA',
  AE = 'AE',
  GB = 'GB',
  US = 'US',
  UM = 'UM',
  UY = 'UY',
  UZ = 'UZ',
  VU = 'VU',
  VE = 'VE',
  VN = 'VN',
  VG = 'VG',
  VI = 'VI',
  WF = 'WF',
  EH = 'EH',
  YE = 'YE',
  ZM = 'ZM',
  ZW = 'ZW'
}

export enum Language {
  AB = 'AB',
  AA = 'AA',
  AF = 'AF',
  AK = 'AK',
  SQ = 'SQ',
  AM = 'AM',
  AR = 'AR',
  AN = 'AN',
  HY = 'HY',
  AS = 'AS',
  AV = 'AV',
  AE = 'AE',
  AY = 'AY',
  AZ = 'AZ',
  BM = 'BM',
  BA = 'BA',
  EU = 'EU',
  BE = 'BE',
  BN = 'BN',
  BH = 'BH',
  BI = 'BI',
  BS = 'BS',
  BR = 'BR',
  BG = 'BG',
  MY = 'MY',
  CA = 'CA',
  CH = 'CH',
  CE = 'CE',
  NY = 'NY',
  ZH = 'ZH',
  CV = 'CV',
  KW = 'KW',
  CO = 'CO',
  CR = 'CR',
  HR = 'HR',
  CS = 'CS',
  DA = 'DA',
  DV = 'DV',
  NL = 'NL',
  EN = 'EN',
  EO = 'EO',
  ET = 'ET',
  EE = 'EE',
  FO = 'FO',
  FJ = 'FJ',
  FI = 'FI',
  FR = 'FR',
  FF = 'FF',
  GL = 'GL',
  KA = 'KA',
  DE = 'DE',
  EL = 'EL',
  GN = 'GN',
  GU = 'GU',
  HT = 'HT',
  HA = 'HA',
  HE = 'HE',
  IW = 'IW',
  HZ = 'HZ',
  HI = 'HI',
  HO = 'HO',
  HU = 'HU',
  IA = 'IA',
  ID = 'ID',
  IE = 'IE',
  GA = 'GA',
  IG = 'IG',
  IK = 'IK',
  IO = 'IO',
  IS = 'IS',
  IT = 'IT',
  IU = 'IU',
  JA = 'JA',
  JV = 'JV',
  KL = 'KL',
  KN = 'KN',
  KR = 'KR',
  KS = 'KS',
  KK = 'KK',
  KM = 'KM',
  KI = 'KI',
  RW = 'RW',
  KY = 'KY',
  KV = 'KV',
  KG = 'KG',
  KO = 'KO',
  KU = 'KU',
  KJ = 'KJ',
  LA = 'LA',
  LB = 'LB',
  LG = 'LG',
  LI = 'LI',
  LN = 'LN',
  LO = 'LO',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  GV = 'GV',
  MK = 'MK',
  MG = 'MG',
  MS = 'MS',
  ML = 'ML',
  MT = 'MT',
  MI = 'MI',
  MR = 'MR',
  MH = 'MH',
  MN = 'MN',
  NA = 'NA',
  NV = 'NV',
  NB = 'NB',
  ND = 'ND',
  NE = 'NE',
  NG = 'NG',
  NN = 'NN',
  NO = 'NO',
  II = 'II',
  NR = 'NR',
  OC = 'OC',
  OJ = 'OJ',
  CU = 'CU',
  OM = 'OM',
  OR = 'OR',
  OS = 'OS',
  PA = 'PA',
  PI = 'PI',
  FA = 'FA',
  PL = 'PL',
  PS = 'PS',
  PT = 'PT',
  QU = 'QU',
  RM = 'RM',
  RN = 'RN',
  RO = 'RO',
  RU = 'RU',
  SA = 'SA',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SM = 'SM',
  SG = 'SG',
  SR = 'SR',
  GD = 'GD',
  SN = 'SN',
  SI = 'SI',
  SK = 'SK',
  SL = 'SL',
  SO = 'SO',
  ST = 'ST',
  ES = 'ES',
  SU = 'SU',
  SW = 'SW',
  SS = 'SS',
  SV = 'SV',
  TA = 'TA',
  TE = 'TE',
  TG = 'TG',
  TH = 'TH',
  TI = 'TI',
  BO = 'BO',
  TK = 'TK',
  TL = 'TL',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TS = 'TS',
  TT = 'TT',
  TW = 'TW',
  TY = 'TY',
  UG = 'UG',
  UK = 'UK',
  UR = 'UR',
  UZ = 'UZ',
  VE = 'VE',
  VI = 'VI',
  VO = 'VO',
  WA = 'WA',
  CY = 'CY',
  WO = 'WO',
  FY = 'FY',
  XH = 'XH',
  YI = 'YI',
  YO = 'YO',
  ZA = 'ZA'
}

export enum Avatar {
  I1 = 'I1',
  I2 = 'I2',
  I3 = 'I3'
}

export type DeviceInfo = {
  __typename?: 'DeviceInfo';
  platform: DevicePlatform;
  deviceName: Scalars['String'];
  country?: Maybe<Countries>;
  language?: Maybe<Language>;
  timezoneOffset: Scalars['Float'];
  timezone: Scalars['String'];
  pushStatus?: Maybe<DevicePushStatus>;
  pushToken?: Maybe<Scalars['String']>;
  appTrackingEnabled?: Maybe<Scalars['Boolean']>;
  appVersion?: Maybe<Scalars['String']>;
  buildNumber?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  screenSize?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export enum DevicePlatform {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  NONE = 'NONE'
}

export enum DevicePushStatus {
  AUTHORIZED = 'AUTHORIZED',
  DENIED = 'DENIED',
  NONE = 'NONE',
  PROVISIONAL = 'PROVISIONAL'
}

export type GameReminder = {
  __typename?: 'GameReminder';
  gameId: Scalars['ObjectId'];
  enabled: Scalars['Boolean'];
  deleted: Scalars['Boolean'];
  weekdays: Array<Scalars['Float']>;
  hour: Scalars['Float'];
  minute: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type PlayedSum = {
  __typename?: 'PlayedSum';
  gameId: Scalars['ObjectId'];
  playedCount: Scalars['Float'];
  firstPlayed?: Maybe<Scalars['DateTime']>;
  lastPlayed?: Maybe<Scalars['DateTime']>;
};

export type UserListRespDto = {
  __typename?: 'UserListRespDto';
  currentPage: Scalars['Float'];
  pageSize: Scalars['Float'];
  totalPages: Scalars['Float'];
  totalRecords: Scalars['Float'];
  users: Array<User>;
};

export type FilterParamsInput = {
  page: Scalars['Float'];
  pageSize: Scalars['Float'];
  order: Scalars['Float'];
  orderBy?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<Scalars['Boolean']>;
};

export type CompaniesHistoryRespDto = {
  __typename?: 'CompaniesHistoryRespDto';
  currentPage: Scalars['Float'];
  pageSize: Scalars['Float'];
  totalPages: Scalars['Float'];
  totalRecords: Scalars['Float'];
  companies: Array<Company>;
};

export type Company = {
  __typename?: 'Company';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  imageSrc?: Maybe<Scalars['String']>;
  bgSrc?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Scalars['ObjectId']>>;
  admins: Array<Scalars['ObjectId']>;
  createdAt: Scalars['DateTime'];
};

export type FilterParamsUserInput = {
  page: Scalars['Float'];
  pageSize: Scalars['Float'];
  order: Scalars['Float'];
  orderBy?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['ObjectId']>;
};

export type CompanyMembersRespDto = {
  __typename?: 'CompanyMembersRespDto';
  admins: Array<User>;
  users: Array<User>;
};

export type CompanyMembersInput = {
  companyId: Scalars['ObjectId'];
};

export type CourseListRespDto = {
  __typename?: 'CourseListRespDto';
  currentPage: Scalars['Float'];
  pageSize: Scalars['Float'];
  totalPages: Scalars['Float'];
  totalRecords: Scalars['Float'];
  courses: Array<Course>;
};

export type Course = {
  __typename?: 'Course';
  _id: Scalars['ObjectId'];
  title: Scalars['String'];
  isFree?: Maybe<Scalars['Boolean']>;
  imageSrc: Scalars['String'];
  units: Array<Unit>;
  hasAccess: Scalars['Boolean'];
};

export type Unit = {
  __typename?: 'Unit';
  _id: Scalars['ObjectId'];
  title: Scalars['String'];
  audios: Array<Audio>;
  imageSrc?: Maybe<Scalars['String']>;
};

export type Audio = {
  __typename?: 'Audio';
  _id: Scalars['ObjectId'];
  type: AudioType;
  title: Scalars['String'];
  /** song length in seconds */
  length: Scalars['Float'];
  src: Scalars['String'];
  version: Scalars['Float'];
  imageSrc?: Maybe<Scalars['String']>;
};

export enum AudioType {
  INSIGHT = 'INSIGHT',
  EXERCISE = 'EXERCISE'
}

export type GameListRespDto = {
  __typename?: 'GameListRespDto';
  currentPage: Scalars['Float'];
  pageSize: Scalars['Float'];
  totalPages: Scalars['Float'];
  totalRecords: Scalars['Float'];
  games: Array<Game>;
};

export type Game = {
  __typename?: 'Game';
  _id: Scalars['ObjectId'];
  title: Scalars['String'];
  subtitle: Scalars['String'];
  isFree?: Maybe<Scalars['Boolean']>;
  isCustomProduction?: Maybe<Scalars['Boolean']>;
  reviews: Array<Review>;
  reviewSum?: Maybe<ReviewSum>;
  daytime?: Maybe<Array<GameDaytime>>;
  levelThreshold: Scalars['Float'];
  backgroundSrc: Scalars['String'];
  algorithmLine: Scalars['String'];
  benefits: Array<Benefits>;
  templateStr: Scalars['String'];
  author?: Maybe<GameAuthor>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  hasAccess: Scalars['Boolean'];
  audioSrc?: Maybe<Scalars['String']>;
  audioFallbackSrc?: Maybe<Scalars['String']>;
  audioVersion?: Maybe<Scalars['Float']>;
  audioLength?: Maybe<Scalars['Float']>;
  phases?: Maybe<Array<GamePhase>>;
  averageTotalPoints?: Maybe<Scalars['Float']>;
  idealTotalPoints?: Maybe<Scalars['Float']>;
  idealAlgorithmChart?: Maybe<Array<Scalars['Float']>>;
  idealHr?: Maybe<Array<Scalars['Float']>>;
  isExclusive?: Maybe<Scalars['Boolean']>;
  forCompany?: Maybe<Scalars['ObjectId']>;
  companiesAccess?: Maybe<Array<Scalars['ObjectId']>>;
  iconSrc: Scalars['String'];
  isFreeIntro: Scalars['Boolean'];
  introVideo?: Maybe<IntroVideo>;
};

export type Review = {
  __typename?: 'Review';
  _id: Scalars['ObjectId'];
  stars: Scalars['Float'];
  content?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  gameId: Scalars['ObjectId'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['ObjectId']>;
  likedFeatures?: Maybe<GameFeature>;
  dislikedFeatures?: Maybe<GameFeature>;
  doRecommend?: Maybe<Scalars['Boolean']>;
  privateFeedback?: Maybe<Scalars['String']>;
  hidden?: Maybe<GameFeature>;
  deleted?: Maybe<GameFeature>;
  markedInAppropriate?: Maybe<GameFeature>;
};

export enum GameFeature {
  VOICE = 'VOICE',
  PSYCHOLOGY = 'PSYCHOLOGY',
  MEASUREMENT = 'MEASUREMENT'
}

export type ReviewSum = {
  __typename?: 'ReviewSum';
  stars: Scalars['Float'];
  count: Scalars['Float'];
  recommendedPercent?: Maybe<Scalars['Float']>;
};

export enum GameDaytime {
  MORNING = 'MORNING',
  EVENING = 'EVENING',
  NOON = 'NOON'
}

export enum Benefits {
  MUSCLEMEMORY = 'MUSCLEMEMORY',
  SUCCESSIMAGERY = 'SUCCESSIMAGERY',
  IMAGERY = 'IMAGERY',
  PSYCHOREGULATION = 'PSYCHOREGULATION',
  FOCUS = 'FOCUS',
  RELAXATION = 'RELAXATION',
  PREPERFORMANCE = 'PREPERFORMANCE',
  ACTIVATION = 'ACTIVATION',
  HEALINGIMAGERY = 'HEALINGIMAGERY',
  REGENERATION = 'REGENERATION'
}

export type GameAuthor = {
  __typename?: 'GameAuthor';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  games?: Maybe<Array<Game>>;
  icon: Scalars['String'];
  text: Scalars['String'];
  templateStr: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type GamePhase = {
  __typename?: 'GamePhase';
  to: Scalars['Float'];
  type: PhaseType;
  nameKey: PhaseNameKey;
  scoreMultiple?: Maybe<Scalars['Float']>;
  benefits?: Maybe<Array<Benefits>>;
  collectPoints?: Maybe<Scalars['Float']>;
  title: Scalars['String'];
  text: Scalars['String'];
};

export enum PhaseType {
  RELAX = 'RELAX',
  ACTIVATE = 'ACTIVATE'
}

export enum PhaseNameKey {
  BASELINE = 'BASELINE',
  ENTERSTADIUM = 'ENTERSTADIUM',
  FOCUS = 'FOCUS',
  RUN = 'RUN',
  COOL_DOWN = 'COOL_DOWN',
  SOCCER_REGENERATION = 'SOCCER_REGENERATION',
  SOCCER_STADIUM = 'SOCCER_STADIUM',
  SOCCER_FOCUS = 'SOCCER_FOCUS',
  SOCCER_CELEBRATE = 'SOCCER_CELEBRATE',
  SOCCER_RELAX = 'SOCCER_RELAX'
}

export type IntroVideo = {
  __typename?: 'IntroVideo';
  videoSrc: Scalars['String'];
  bgSrc: Scalars['String'];
  title: Scalars['String'];
  text: Scalars['String'];
};

export type GameAuthorsListRespDto = {
  __typename?: 'GameAuthorsListRespDto';
  currentPage: Scalars['Float'];
  pageSize: Scalars['Float'];
  totalPages: Scalars['Float'];
  totalRecords: Scalars['Float'];
  authors: Array<GameAuthor>;
};

export type DiscoverDto = {
  __typename?: 'DiscoverDto';
  templateStr: Scalars['String'];
  games: Array<Game>;
  companies: Array<Company>;
};

export type GameHistory = {
  __typename?: 'GameHistory';
  _id: Scalars['ObjectId'];
  game: Scalars['ObjectId'];
  user: Scalars['ObjectId'];
  startDate: Scalars['DateTime'];
  deviceType?: Maybe<DeviceType>;
  deviceName?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  trackingHrRaw: Array<Scalars['Int']>;
  trackingHr: Array<Scalars['Int']>;
  trackingSignal: Array<Scalars['Float']>;
  trackingRrs: Array<Scalars['String']>;
  hasValidTracking: Scalars['Boolean'];
  trackingQuality?: Maybe<Scalars['Float']>;
  algorithm?: Maybe<GameAlgorithm>;
  pointsTotal: Scalars['Float'];
  pointsPhases: Array<Scalars['Float']>;
  pointsBenefits?: Maybe<Array<PointsBenefits>>;
  algorithmChart?: Maybe<Array<Scalars['Float']>>;
  challenged?: Maybe<User>;
  challengedGameHistory?: Maybe<GameHistory>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  algorithmName: Scalars['String'];
  accuracy?: Maybe<Scalars['Float']>;
};

export enum DeviceType {
  CAMERA = 'CAMERA',
  POLAR = 'POLAR',
  APPLEWATCH = 'APPLEWATCH',
  NONE = 'NONE',
  RANDOM = 'RANDOM'
}

export enum GameAlgorithm {
  ARCV1 = 'ARCV1',
  ARCV1_2 = 'ARCV1_2',
  ARCV1_3 = 'ARCV1_3'
}

export type PointsBenefits = {
  __typename?: 'PointsBenefits';
  benefit: Benefits;
  points: Scalars['Int'];
};

export type BenefitDto = {
  __typename?: 'BenefitDto';
  _id: Benefits;
  name: Benefits;
  title: Scalars['String'];
  color: Scalars['String'];
  templateStr: Scalars['String'];
};

export type LicensHistoryRespDto = {
  __typename?: 'LicensHistoryRespDto';
  currentPage: Scalars['Float'];
  pageSize: Scalars['Float'];
  totalPages: Scalars['Float'];
  totalRecords: Scalars['Float'];
  licenses: Array<License>;
};

export type License = {
  __typename?: 'License';
  _id: Scalars['ObjectId'];
  productsId: Array<Product>;
  userId?: Maybe<User>;
  subscriptionId?: Maybe<Subscription>;
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Product = {
  __typename?: 'Product';
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  isSubscription: Scalars['Boolean'];
  isExclusive?: Maybe<Scalars['Boolean']>;
  exclusiveSubscribers?: Maybe<ExclusiveSubscribers>;
  maxSharedNumber: Scalars['Float'];
  price: Scalars['Float'];
  discount?: Maybe<Scalars['Float']>;
  appleProductId?: Maybe<Scalars['String']>;
  googleProductId?: Maybe<Scalars['String']>;
  game?: Maybe<Array<Scalars['ObjectId']>>;
  course?: Maybe<Array<Scalars['ObjectId']>>;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExclusiveSubscribers = {
  __typename?: 'ExclusiveSubscribers';
  exclusiveType: ExclusiveType;
  user?: Maybe<Scalars['ObjectId']>;
  company?: Maybe<Scalars['ObjectId']>;
};

export enum ExclusiveType {
  COMPANY = 'COMPANY',
  USER = 'USER'
}

export type Subscription = {
  __typename?: 'Subscription';
  _id: Scalars['ObjectId'];
  productsId: Array<Product>;
  gateway?: Maybe<GatewayType>;
  gatewaySubscriptionId?: Maybe<Scalars['String']>;
  status: SubscriptionStatus;
  userId?: Maybe<User>;
  companyId?: Maybe<Company>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  discount?: Maybe<Discount>;
  maxShareCount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  originalStartDate?: Maybe<Scalars['DateTime']>;
  isTrial?: Maybe<Scalars['Boolean']>;
  nextRenew?: Maybe<Scalars['Boolean']>;
  eventLog?: Maybe<Scalars['String']>;
  source?: Maybe<SubscriptionSource>;
  purchaseEmail?: Maybe<Scalars['String']>;
  shareWith?: Maybe<Array<User>>;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  transactions?: Maybe<Array<Transaction>>;
};

export enum GatewayType {
  APPLE = 'APPLE',
  GOOGLE = 'GOOGLE',
  STRIPE = 'STRIPE',
  PAYPAL = 'PAYPAL',
  BANKTRANSFER = 'BANKTRANSFER',
  MANUAL = 'MANUAL',
  FREE = 'FREE'
}

export enum SubscriptionStatus {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED'
}

export type Discount = {
  __typename?: 'Discount';
  value?: Maybe<Scalars['Float']>;
  promotionCode?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type SubscriptionSource = {
  __typename?: 'SubscriptionSource';
  ip?: Maybe<Scalars['String']>;
  ipCountry?: Maybe<Countries>;
  utm_source?: Maybe<Scalars['String']>;
  utm_medium?: Maybe<Scalars['String']>;
  utm_campaign?: Maybe<Scalars['String']>;
  utm_term?: Maybe<Scalars['String']>;
  utm_content?: Maybe<Scalars['String']>;
  fbp?: Maybe<Scalars['String']>;
  fbc?: Maybe<Scalars['String']>;
  referrer?: Maybe<Scalars['String']>;
  funnel?: Maybe<Scalars['String']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  _id: Scalars['ObjectId'];
  gateway: GatewayType;
  gatewayTransactionId?: Maybe<Scalars['String']>;
  gatewayOriginalTransactionId?: Maybe<Scalars['String']>;
  purchaseToken?: Maybe<Scalars['String']>;
  productsId: Array<Product>;
  subscriptionId: Subscription;
  refund?: Maybe<Array<Refund>>;
  status: TransactionStatus;
  isTrial?: Maybe<Scalars['Boolean']>;
  eventLog: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  payment?: Maybe<Payment>;
  invoiceData?: Maybe<InvoiceData>;
  isSandbox?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Refund = {
  __typename?: 'Refund';
  value?: Maybe<Scalars['Float']>;
  refundDate?: Maybe<Scalars['DateTime']>;
};

export enum TransactionStatus {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REFUNDED = 'REFUNDED',
  FAILED = 'FAILED'
}

export type Payment = {
  __typename?: 'Payment';
  priceGross: Scalars['Float'];
  currency: Currencies;
};

export enum Currencies {
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  AUD = 'AUD',
  AWG = 'AWG',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BRL = 'BRL',
  BSD = 'BSD',
  BTN = 'BTN',
  BWP = 'BWP',
  BYN = 'BYN',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CHF = 'CHF',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  CRC = 'CRC',
  CUC = 'CUC',
  CUP = 'CUP',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EGP = 'EGP',
  ERN = 'ERN',
  ETB = 'ETB',
  EUR = 'EUR',
  FJD = 'FJD',
  FKP = 'FKP',
  GBP = 'GBP',
  GEL = 'GEL',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRU = 'MRU',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PYG = 'PYG',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SLL = 'SLL',
  SOS = 'SOS',
  SRD = 'SRD',
  SSP = 'SSP',
  STN = 'STN',
  SVC = 'SVC',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  USD = 'USD',
  UYU = 'UYU',
  UZS = 'UZS',
  VES = 'VES',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XAF = 'XAF',
  XCD = 'XCD',
  XOF = 'XOF',
  XPF = 'XPF',
  YER = 'YER',
  ZAR = 'ZAR',
  ZMW = 'ZMW',
  ZWL = 'ZWL',
  DEFAULT = 'DEFAULT'
}

export type InvoiceData = {
  __typename?: 'InvoiceData';
  country: Countries;
  vatPercent: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type GetCompanyLicenseInput = {
  companyId: Scalars['ObjectId'];
};

export type ProductHistoryRespDto = {
  __typename?: 'ProductHistoryRespDto';
  currentPage: Scalars['Float'];
  pageSize: Scalars['Float'];
  totalPages: Scalars['Float'];
  totalRecords: Scalars['Float'];
  products: Array<Product>;
};

export type GetProductInput = {
  _id: Scalars['ObjectId'];
};

export type GetExclusiveProductsInput = {
  companyId?: InputMaybe<Scalars['ObjectId']>;
  userId?: InputMaybe<Scalars['ObjectId']>;
};

export type Sport = {
  __typename?: 'Sport';
  _id: Scalars['ObjectId'];
  title: Scalars['String'];
  users?: Maybe<Array<Scalars['ObjectId']>>;
  imageSrc: Scalars['String'];
  masterClass: Array<MasterClass>;
  activateDate?: Maybe<Scalars['DateTime']>;
  isAccessible?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type MasterClass = {
  __typename?: 'MasterClass';
  _id: Scalars['ObjectId'];
  title: Scalars['String'];
  imageSrc: Scalars['String'];
  course?: Maybe<Course>;
  game?: Maybe<Game>;
};

export type SportHistoryRespDto = {
  __typename?: 'SportHistoryRespDto';
  currentPage: Scalars['Float'];
  pageSize: Scalars['Float'];
  totalPages: Scalars['Float'];
  totalRecords: Scalars['Float'];
  sports: Array<Sport>;
};

export type SubscriptionHistoryRespDto = {
  __typename?: 'SubscriptionHistoryRespDto';
  currentPage: Scalars['Float'];
  pageSize: Scalars['Float'];
  totalPages: Scalars['Float'];
  totalRecords: Scalars['Float'];
  subscriptions: Array<Subscription>;
};

export type ManageSubscriptionInput = {
  subscriptionId: Scalars['ObjectId'];
  gatewaySubscriptionId?: InputMaybe<Scalars['String']>;
  gateway?: InputMaybe<GatewayType>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  source?: InputMaybe<Scalars['String']>;
};

export type TransactionHistoryRespDto = {
  __typename?: 'TransactionHistoryRespDto';
  currentPage: Scalars['Float'];
  pageSize: Scalars['Float'];
  totalPages: Scalars['Float'];
  totalRecords: Scalars['Float'];
  transactions: Array<Transaction>;
};

export type Mutation = {
  __typename?: 'Mutation';
  invitedCodeVerification: Scalars['Boolean'];
  emailSignUp: User;
  signUpInternal: User;
  signUpEmailConfirmation: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  forgotPasswordConfirmation: Scalars['Boolean'];
  forgotPasswordSetNew: Scalars['Boolean'];
  signUpWithGoogle: User;
  signInWithGoogle: User;
  signUpWithApple: User;
  signInWithApple: User;
  waitingListSignup: Scalars['Boolean'];
  waitingListSignupConfirm: Scalars['Boolean'];
  emailSignIn: User;
  adminSignIn: User;
  refreshToken: Scalars['Boolean'];
  signOut: Scalars['Boolean'];
  createInvitedCode: Scalars['Boolean'];
  updateUser: User;
  adminUpdateUser: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  sendInvitation: Scalars['Boolean'];
  acceptFriendRequest: User;
  deleteFriend: User;
  isValidUsername: ValidUsername;
  updateDeviceInfo: Scalars['Boolean'];
  setGameReminder: User;
  playedAudio: User;
  createAudio: Audio;
  createCompany: Company;
  updateCompany: Scalars['Boolean'];
  addUsersToCompany: Scalars['Boolean'];
  addAdminUsersToCompany: Scalars['Boolean'];
  sendCompanyInvitation: Scalars['Boolean'];
  removeCompanyUsers: Scalars['Boolean'];
  deleteCompany: Scalars['Boolean'];
  shareCompanySubscription: Scalars['Boolean'];
  deleteCourse: Scalars['Boolean'];
  createCourse: Course;
  createGameAuthor: GameAuthor;
  deleteGameAuthor: Scalars['Boolean'];
  updateGameAuthor: Scalars['Boolean'];
  deleteGame: Scalars['Boolean'];
  updateDiscoverTemplate: Scalars['String'];
  createGame: Game;
  updateGame: Game;
  addPlayedGameToHistory: GameHistory;
  addReview: AddReviewDto;
  deleteReview: ReviewSum;
  hideReview: ReviewSum;
  markInAppropriate: Scalars['Boolean'];
  createProduct: Product;
  updateProduct: Product;
  deleteProduct: Scalars['Boolean'];
  createMasterClass: MasterClass;
  createSport: Sport;
  deleteSport: Scalars['Boolean'];
  createSubscription: Subscription;
  createSubscriptionByAdmin: Subscription;
  deleteSubscription: Scalars['Boolean'];
  refreshSubscriptionLicenses: Scalars['Boolean'];
  cancelSubscription: Scalars['Boolean'];
  createTransactionManually: Transaction;
  validateAppleTransaction: Transaction;
  validateGoogleTransaction: Transaction;
  restoreApplePurchase: Scalars['Boolean'];
  restoreGooglePurchase: Scalars['Boolean'];
  deleteTransaction: Scalars['Boolean'];
  refundTransaction: Scalars['Boolean'];
};


export type MutationInvitedCodeVerificationArgs = {
  input: InvitedCodeInput;
};


export type MutationEmailSignUpArgs = {
  input: SignUpInput;
};


export type MutationSignUpInternalArgs = {
  input: SignUpInternalInput;
};


export type MutationSignUpEmailConfirmationArgs = {
  input: EmailVerifyInput;
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationForgotPasswordConfirmationArgs = {
  input: ForgotPasswordConfimationInput;
};


export type MutationForgotPasswordSetNewArgs = {
  input: ForgotPasswordSetNewInput;
};


export type MutationSignUpWithGoogleArgs = {
  input: GoogleSignUpInput;
};


export type MutationSignInWithGoogleArgs = {
  input: GoogleSignInInput;
};


export type MutationSignUpWithAppleArgs = {
  input: AppleSignUpInput;
};


export type MutationSignInWithAppleArgs = {
  input: AppleSignInInput;
};


export type MutationWaitingListSignupArgs = {
  input: EmailVerifyInput;
};


export type MutationWaitingListSignupConfirmArgs = {
  input: WaitingListInput;
};


export type MutationEmailSignInArgs = {
  input: SignInInput;
};


export type MutationAdminSignInArgs = {
  input: SignInInput;
};


export type MutationCreateInvitedCodeArgs = {
  input: InvitedCodeInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationAdminUpdateUserArgs = {
  input: AdminUpdateUserInput;
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationIsValidUsernameArgs = {
  input: ValidUsernameInput;
};


export type MutationUpdateDeviceInfoArgs = {
  input: DeviceInfoInput;
};


export type MutationSetGameReminderArgs = {
  input: GameReminderInput;
};


export type MutationPlayedAudioArgs = {
  input: PlayedAudioInput;
};


export type MutationCreateAudioArgs = {
  input: CreateAudioInput;
};


export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};


export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput;
};


export type MutationAddUsersToCompanyArgs = {
  input: AddUsersToCompanyInput;
};


export type MutationAddAdminUsersToCompanyArgs = {
  input: AddAdminUsersToCompanyInput;
};


export type MutationSendCompanyInvitationArgs = {
  input: SendCompanyInvitationInput;
};


export type MutationRemoveCompanyUsersArgs = {
  input: RemoveCompanyUsersInput;
};


export type MutationDeleteCompanyArgs = {
  input: DeleteCompanyInput;
};


export type MutationShareCompanySubscriptionArgs = {
  input: ShareLicenseInput;
};


export type MutationDeleteCourseArgs = {
  input: DeleteCourseInput;
};


export type MutationCreateCourseArgs = {
  input: CreateCourseInput;
};


export type MutationCreateGameAuthorArgs = {
  input: CreateGameAuthorInput;
};


export type MutationDeleteGameAuthorArgs = {
  input: DeleteGameAuthorInput;
};


export type MutationUpdateGameAuthorArgs = {
  input: UpdateGameAuthorInput;
};


export type MutationDeleteGameArgs = {
  input: DeleteGameInput;
};


export type MutationUpdateDiscoverTemplateArgs = {
  templateStr: Scalars['String'];
};


export type MutationCreateGameArgs = {
  input: CreateGameInput;
};


export type MutationUpdateGameArgs = {
  input: UpdateGameInput;
};


export type MutationAddPlayedGameToHistoryArgs = {
  input: AddPlayedGameInput;
};


export type MutationAddReviewArgs = {
  input: AddReviewInput;
};


export type MutationDeleteReviewArgs = {
  input: DeleteReviewInput;
};


export type MutationHideReviewArgs = {
  input: HideReviewInput;
};


export type MutationMarkInAppropriateArgs = {
  input: MarkAppropriateInput;
};


export type MutationCreateProductArgs = {
  input: CreateProductInput;
};


export type MutationUpdateProductArgs = {
  input: UpdateProductInput;
};


export type MutationDeleteProductArgs = {
  input: DeleteProductInput;
};


export type MutationCreateMasterClassArgs = {
  input: MasterClassInput;
};


export type MutationCreateSportArgs = {
  input: CreateSportAndMasterClassInput;
};


export type MutationDeleteSportArgs = {
  input: DeleteSportInput;
};


export type MutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput;
};


export type MutationCreateSubscriptionByAdminArgs = {
  input: CreateAdminSubscriptionInput;
};


export type MutationDeleteSubscriptionArgs = {
  input: DeleteSubscriptionInput;
};


export type MutationRefreshSubscriptionLicensesArgs = {
  input: DeleteSubscriptionInput;
};


export type MutationCancelSubscriptionArgs = {
  input: ManageSubscriptionInput;
};


export type MutationCreateTransactionManuallyArgs = {
  input: ManualTransactionInput;
};


export type MutationValidateAppleTransactionArgs = {
  input: ValidateAppleTransactionInput;
};


export type MutationValidateGoogleTransactionArgs = {
  input: ValidateGoogleTransactionInput;
};


export type MutationRestoreApplePurchaseArgs = {
  input: RestoreApplePurchaseInput;
};


export type MutationRestoreGooglePurchaseArgs = {
  input: RestoreGooglePurchaseInput;
};


export type MutationDeleteTransactionArgs = {
  input: DeleteTransactionInput;
};


export type MutationRefundTransactionArgs = {
  input: DeleteTransactionInput;
};

export type InvitedCodeInput = {
  invitedCode?: InputMaybe<Scalars['String']>;
};

export type SignUpInput = {
  invitedCode?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  language?: InputMaybe<Language>;
  country?: InputMaybe<Countries>;
  avatar?: InputMaybe<Avatar>;
  username?: InputMaybe<Scalars['String']>;
  emailConfirmCode: Scalars['String'];
  password: Scalars['String'];
  email: Scalars['String'];
};

export type SignUpInternalInput = {
  invitedCode?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  language?: InputMaybe<Language>;
  country?: InputMaybe<Countries>;
  avatar?: InputMaybe<Avatar>;
  username?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
  giveAccess: Scalars['Boolean'];
  role?: InputMaybe<UserRoles>;
};

export type EmailVerifyInput = {
  email: Scalars['String'];
};

export type ForgotPasswordInput = {
  userPayload: Scalars['String'];
};

export type ForgotPasswordConfimationInput = {
  userPayload: Scalars['String'];
  confirmCode: Scalars['String'];
};

export type ForgotPasswordSetNewInput = {
  userPayload: Scalars['String'];
  confirmCode: Scalars['String'];
  password: Scalars['String'];
};

export type GoogleSignUpInput = {
  invitedCode?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  language?: InputMaybe<Language>;
  country?: InputMaybe<Countries>;
  avatar?: InputMaybe<Avatar>;
  username?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
};

export type GoogleSignInInput = {
  invitedCode?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  language?: InputMaybe<Language>;
  country?: InputMaybe<Countries>;
  avatar?: InputMaybe<Avatar>;
  username?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
};

export type AppleSignUpInput = {
  invitedCode?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  language?: InputMaybe<Language>;
  country?: InputMaybe<Countries>;
  avatar?: InputMaybe<Avatar>;
  username?: InputMaybe<Scalars['String']>;
  identityToken: Scalars['String'];
  authorizationCode: Scalars['String'];
};

export type AppleSignInInput = {
  identityToken: Scalars['String'];
  authorizationCode: Scalars['String'];
};

export type WaitingListInput = {
  email: Scalars['String'];
  emailConfirmCode: Scalars['String'];
};

export type SignInInput = {
  userPayload: Scalars['String'];
  password: Scalars['String'];
};

export type UpdateUserInput = {
  avatar?: InputMaybe<Avatar>;
  firstName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Countries>;
  username?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserRoles>;
};

export type AdminUpdateUserInput = {
  avatar?: InputMaybe<Avatar>;
  firstName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Countries>;
  username?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserRoles>;
  _id: Scalars['ObjectId'];
  email?: InputMaybe<Scalars['String']>;
  giveAccess: Scalars['Boolean'];
};

export type DeleteUserInput = {
  userId: Scalars['ObjectId'];
};

export type ValidUsername = {
  __typename?: 'ValidUsername';
  valid: Scalars['Boolean'];
  suggestions: Array<Scalars['String']>;
};

export type ValidUsernameInput = {
  username?: InputMaybe<Scalars['String']>;
};

export type DeviceInfoInput = {
  platform: DevicePlatform;
  deviceName: Scalars['String'];
  country?: InputMaybe<Countries>;
  language?: InputMaybe<Language>;
  timezoneOffset: Scalars['Float'];
  timezone: Scalars['String'];
  pushStatus?: InputMaybe<DevicePushStatus>;
  pushToken?: InputMaybe<Scalars['String']>;
  appTrackingEnabled?: InputMaybe<Scalars['Boolean']>;
  appVersion?: InputMaybe<Scalars['String']>;
  buildNumber?: InputMaybe<Scalars['String']>;
  osVersion?: InputMaybe<Scalars['String']>;
  screenSize?: InputMaybe<Scalars['String']>;
};

export type GameReminderInput = {
  gameId: Scalars['ObjectId'];
  enabled: Scalars['Boolean'];
  deleted: Scalars['Boolean'];
  weekdays: Array<Scalars['Float']>;
  hour: Scalars['Float'];
  minute: Scalars['Float'];
};

export type PlayedAudioInput = {
  playlistId: Scalars['ObjectId'];
  playlistType: PlaylistType;
  audioId: Scalars['ObjectId'];
  playedTo: Scalars['Int'];
  finished: Scalars['Boolean'];
};

export type CreateAudioInput = {
  type: AudioType;
  title: Scalars['String'];
  length: Scalars['Float'];
  src: Scalars['String'];
  version: Scalars['Float'];
  imageSrc: Scalars['String'];
};

export type CreateCompanyInput = {
  name: Scalars['String'];
  imageSrc?: InputMaybe<Scalars['String']>;
  bgSrc?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<Array<Scalars['ObjectId']>>;
  admins?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type UpdateCompanyInput = {
  _id: Scalars['ObjectId'];
  name: Scalars['String'];
  imageSrc?: InputMaybe<Scalars['String']>;
  bgSrc?: InputMaybe<Scalars['String']>;
};

export type AddUsersToCompanyInput = {
  companyId: Scalars['ObjectId'];
  users: Array<Scalars['ObjectId']>;
};

export type AddAdminUsersToCompanyInput = {
  companyId: Scalars['ObjectId'];
  users: Array<Scalars['ObjectId']>;
};

export type SendCompanyInvitationInput = {
  companyId: Scalars['ObjectId'];
  email: Scalars['String'];
};

export type RemoveCompanyUsersInput = {
  companyId: Scalars['ObjectId'];
  userIds: Array<Scalars['ObjectId']>;
  removeOnlyAsAdmin: Scalars['Boolean'];
};

export type DeleteCompanyInput = {
  companyId: Scalars['ObjectId'];
};

export type ShareLicenseInput = {
  subscriptionId: Scalars['ObjectId'];
  userIds: Array<Scalars['ObjectId']>;
};

export type DeleteCourseInput = {
  courseId: Scalars['ObjectId'];
};

export type CreateCourseInput = {
  title: Scalars['String'];
  imageSrc?: InputMaybe<Scalars['String']>;
  units: Array<UnitInput>;
};

export type UnitInput = {
  title: Scalars['String'];
  imageSrc?: InputMaybe<Scalars['String']>;
  audios: Array<Scalars['String']>;
};

export type CreateGameAuthorInput = {
  name: Scalars['String'];
  gameId: Scalars['ObjectId'];
  icon: Scalars['String'];
  text: Scalars['String'];
  templateStr: Scalars['String'];
};

export type DeleteGameAuthorInput = {
  authorId: Scalars['ObjectId'];
};

export type UpdateGameAuthorInput = {
  name: Scalars['String'];
  gameId: Scalars['ObjectId'];
  icon: Scalars['String'];
  text: Scalars['String'];
  templateStr: Scalars['String'];
  _id: Scalars['ObjectId'];
};

export type DeleteGameInput = {
  gameId: Scalars['ObjectId'];
};

export type CreateGameInput = {
  title?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  isFree: Scalars['Boolean'];
  isCustomProduction: Scalars['Boolean'];
  audioSrc: Scalars['String'];
  audioFallbackSrc: Scalars['String'];
  audioVersion: Scalars['Float'];
  audioLength: Scalars['Float'];
  iconSrc?: InputMaybe<Scalars['String']>;
  backgroundSrc: Scalars['String'];
  phases: Array<GamePhaseInput>;
  algorithmLine: Scalars['String'];
  templateStr: Scalars['String'];
  averageTotalPoints: Scalars['Float'];
  idealTotalPoints: Scalars['Float'];
  idealAlgorithmChart: Array<Scalars['Int']>;
  idealHr: Array<Scalars['Int']>;
  benefits?: InputMaybe<Array<Benefits>>;
  author?: InputMaybe<Scalars['ObjectId']>;
};

export type GamePhaseInput = {
  to: Scalars['Float'];
  type: PhaseType;
  nameKey: PhaseNameKey;
  scoreMultiple: Scalars['Float'];
  benefits?: InputMaybe<Array<Benefits>>;
};

export type UpdateGameInput = {
  title?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  isFree: Scalars['Boolean'];
  isCustomProduction: Scalars['Boolean'];
  audioSrc: Scalars['String'];
  audioFallbackSrc: Scalars['String'];
  audioVersion: Scalars['Float'];
  audioLength: Scalars['Float'];
  iconSrc?: InputMaybe<Scalars['String']>;
  backgroundSrc: Scalars['String'];
  phases: Array<GamePhaseInput>;
  algorithmLine: Scalars['String'];
  templateStr: Scalars['String'];
  averageTotalPoints: Scalars['Float'];
  idealTotalPoints: Scalars['Float'];
  idealAlgorithmChart: Array<Scalars['Int']>;
  idealHr: Array<Scalars['Int']>;
  benefits?: InputMaybe<Array<Benefits>>;
  author?: InputMaybe<Scalars['ObjectId']>;
  _id: Scalars['ObjectId'];
};

export type AddPlayedGameInput = {
  trackingHr: Array<Scalars['Int']>;
  trackingSignal: Array<Scalars['Float']>;
  trackingRrs: Array<Scalars['String']>;
  gameId: Scalars['ObjectId'];
  didContinueWithoutTracking: Scalars['Boolean'];
  startDate: Scalars['DateTime'];
  deviceType?: InputMaybe<DeviceType>;
  deviceName?: InputMaybe<Scalars['String']>;
  deviceId?: InputMaybe<Scalars['String']>;
};

export type AddReviewDto = {
  __typename?: 'AddReviewDto';
  review: Review;
  reviewSum: ReviewSum;
};

export type AddReviewInput = {
  stars: Scalars['Float'];
  content?: InputMaybe<Scalars['String']>;
  gameId: Scalars['ObjectId'];
  likedFeatures?: InputMaybe<GameFeature>;
  dislikedFeatures?: InputMaybe<GameFeature>;
  doRecommend?: InputMaybe<Scalars['Boolean']>;
  privateFeedback?: InputMaybe<Scalars['String']>;
};

export type DeleteReviewInput = {
  reviewId: Scalars['ObjectId'];
};

export type HideReviewInput = {
  reviewId: Scalars['ObjectId'];
};

export type MarkAppropriateInput = {
  reviewId: Scalars['ObjectId'];
};

export type CreateProductInput = {
  name: Scalars['String'];
  isExclusive: Scalars['Boolean'];
  exclusiveSubscribers?: InputMaybe<ExclusiveSubscribersInput>;
  isSubscription: Scalars['Boolean'];
  price: Scalars['Float'];
  maxSharedNumber?: InputMaybe<Scalars['Float']>;
  discount?: InputMaybe<Scalars['Float']>;
  appleProductId?: InputMaybe<Scalars['String']>;
  googleProductId?: InputMaybe<Scalars['String']>;
  game?: InputMaybe<Array<Scalars['ObjectId']>>;
  course?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type ExclusiveSubscribersInput = {
  exclusiveType: ExclusiveType;
  user?: InputMaybe<Scalars['ObjectId']>;
  company?: InputMaybe<Scalars['ObjectId']>;
};

export type UpdateProductInput = {
  name: Scalars['String'];
  isExclusive: Scalars['Boolean'];
  exclusiveSubscribers?: InputMaybe<ExclusiveSubscribersInput>;
  isSubscription: Scalars['Boolean'];
  price: Scalars['Float'];
  maxSharedNumber?: InputMaybe<Scalars['Float']>;
  discount?: InputMaybe<Scalars['Float']>;
  appleProductId?: InputMaybe<Scalars['String']>;
  googleProductId?: InputMaybe<Scalars['String']>;
  game?: InputMaybe<Array<Scalars['ObjectId']>>;
  course?: InputMaybe<Array<Scalars['ObjectId']>>;
  _id: Scalars['ObjectId'];
};

export type DeleteProductInput = {
  productId: Scalars['ObjectId'];
};

export type MasterClassInput = {
  title: Scalars['String'];
  imageSrc?: InputMaybe<Scalars['String']>;
  course?: InputMaybe<Scalars['ObjectId']>;
  game?: InputMaybe<Scalars['ObjectId']>;
};

export type CreateSportAndMasterClassInput = {
  title: Scalars['String'];
  imageSrc?: InputMaybe<Scalars['String']>;
  masterClass: Array<MasterClassInput>;
};

export type DeleteSportInput = {
  sportId: Scalars['ObjectId'];
};

export type CreateSubscriptionInput = {
  productsId?: InputMaybe<Array<Scalars['ObjectId']>>;
  googleProductsId?: InputMaybe<Array<Scalars['String']>>;
  appleProductsId?: InputMaybe<Array<Scalars['String']>>;
  gateway?: InputMaybe<GatewayType>;
  /** deprecated */
  productId?: InputMaybe<Scalars['ObjectId']>;
};

export type CreateAdminSubscriptionInput = {
  productsId?: InputMaybe<Array<Scalars['ObjectId']>>;
  googleProductsId?: InputMaybe<Array<Scalars['String']>>;
  appleProductsId?: InputMaybe<Array<Scalars['String']>>;
  gateway?: InputMaybe<GatewayType>;
  status: SubscriptionStatus;
  userId: Scalars['ObjectId'];
  companyId: Scalars['ObjectId'];
  isRecurring?: InputMaybe<Scalars['Boolean']>;
  maxShareCount: Scalars['Float'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  purchaseEmail?: InputMaybe<Scalars['String']>;
  eventLog?: InputMaybe<Scalars['String']>;
};

export type DeleteSubscriptionInput = {
  subscriptionId: Scalars['ObjectId'];
};

export type ManualTransactionInput = {
  gatewayTransactionId?: InputMaybe<Scalars['String']>;
  gatewayOriginalTransactionId?: InputMaybe<Scalars['String']>;
  gateway?: InputMaybe<GatewayType>;
  country: Countries;
  price: Scalars['Float'];
  currency: Currencies;
  productsId: Array<Scalars['ObjectId']>;
  companyId?: InputMaybe<Scalars['ObjectId']>;
  userId?: InputMaybe<Scalars['ObjectId']>;
  vatPercent: Scalars['Float'];
  startDate?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  maxSharedNumber?: InputMaybe<Scalars['Float']>;
};

export type ValidateAppleTransactionInput = {
  receipt: Scalars['String'];
  country: Countries;
  price: Scalars['Float'];
  currency: Scalars['String'];
};

export type ValidateGoogleTransactionInput = {
  receipt: Scalars['String'];
  country: Countries;
  price: Scalars['Float'];
  currency: Scalars['String'];
};

export type RestoreApplePurchaseInput = {
  receiptHistories: Array<ValidateAppleTransactionInput>;
};

export type RestoreGooglePurchaseInput = {
  receiptHistories: Array<ValidateAppleTransactionInput>;
};

export type DeleteTransactionInput = {
  transactionId: Scalars['ObjectId'];
};

export type WaSubscriptionFragment = { __typename?: 'Subscription', _id: any, createdAt: any, isTrial?: boolean | null, gateway?: GatewayType | null, startDate?: any | null, gatewaySubscriptionId?: string | null, nextRenew?: boolean | null, status: SubscriptionStatus, isRecurring?: boolean | null, transactions?: Array<{ __typename?: 'Transaction', _id: any, status: TransactionStatus, isTrial?: boolean | null, startDate?: any | null, endDate?: any | null, createdAt: any, payment?: { __typename?: 'Payment', priceGross: number, currency: Currencies } | null, invoiceData?: { __typename?: 'InvoiceData', country: Countries, vatPercent: number } | null, refund?: Array<{ __typename?: 'Refund', value?: number | null }> | null }> | null };

export type WaTransactionFragment = { __typename?: 'Transaction', _id: any, status: TransactionStatus, isTrial?: boolean | null, startDate?: any | null, endDate?: any | null, createdAt: any, payment?: { __typename?: 'Payment', priceGross: number, currency: Currencies } | null, invoiceData?: { __typename?: 'InvoiceData', country: Countries, vatPercent: number } | null, refund?: Array<{ __typename?: 'Refund', value?: number | null }> | null };

export type CancelSubscriptionMutationVariables = Exact<{
  input: ManageSubscriptionInput;
}>;


export type CancelSubscriptionMutation = { __typename?: 'Mutation', cancelSubscription: boolean };

export type GetSubscriptionWTxnsQueryVariables = Exact<{
  input: ManageSubscriptionInput;
}>;


export type GetSubscriptionWTxnsQuery = { __typename?: 'Query', getSubscriptionWTxns: Array<{ __typename?: 'Subscription', _id: any, createdAt: any, isTrial?: boolean | null, gateway?: GatewayType | null, startDate?: any | null, gatewaySubscriptionId?: string | null, nextRenew?: boolean | null, status: SubscriptionStatus, isRecurring?: boolean | null, transactions?: Array<{ __typename?: 'Transaction', _id: any, status: TransactionStatus, isTrial?: boolean | null, startDate?: any | null, endDate?: any | null, createdAt: any, payment?: { __typename?: 'Payment', priceGross: number, currency: Currencies } | null, invoiceData?: { __typename?: 'InvoiceData', country: Countries, vatPercent: number } | null, refund?: Array<{ __typename?: 'Refund', value?: number | null }> | null }> | null }> };

export type IsLoggedInQueryVariables = Exact<{ [key: string]: never; }>;


export type IsLoggedInQuery = { __typename?: 'Query', isLoggedIn: boolean };

export const WaTransactionFragmentDoc = gql`
    fragment WATransaction on Transaction {
  _id
  status
  isTrial
  payment {
    priceGross
    currency
  }
  startDate
  endDate
  createdAt
  invoiceData {
    country
    vatPercent
  }
  refund {
    value
  }
}
    `;
export const WaSubscriptionFragmentDoc = gql`
    fragment WASubscription on Subscription {
  _id
  createdAt
  isTrial
  gateway
  startDate
  createdAt
  gatewaySubscriptionId
  nextRenew
  status
  isRecurring
  transactions {
    ...WATransaction
  }
}
    ${WaTransactionFragmentDoc}`;
export const CancelSubscriptionDocument = gql`
    mutation CancelSubscription($input: ManageSubscriptionInput!) {
  cancelSubscription(input: $input)
}
    `;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>(CancelSubscriptionDocument, options);
      }
export type CancelSubscriptionMutationHookResult = ReturnType<typeof useCancelSubscriptionMutation>;
export type CancelSubscriptionMutationResult = Apollo.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;
export const GetSubscriptionWTxnsDocument = gql`
    query GetSubscriptionWTxns($input: ManageSubscriptionInput!) {
  getSubscriptionWTxns(input: $input) {
    ...WASubscription
  }
}
    ${WaSubscriptionFragmentDoc}`;

/**
 * __useGetSubscriptionWTxnsQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionWTxnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionWTxnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionWTxnsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSubscriptionWTxnsQuery(baseOptions: Apollo.QueryHookOptions<GetSubscriptionWTxnsQuery, GetSubscriptionWTxnsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscriptionWTxnsQuery, GetSubscriptionWTxnsQueryVariables>(GetSubscriptionWTxnsDocument, options);
      }
export function useGetSubscriptionWTxnsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionWTxnsQuery, GetSubscriptionWTxnsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscriptionWTxnsQuery, GetSubscriptionWTxnsQueryVariables>(GetSubscriptionWTxnsDocument, options);
        }
export type GetSubscriptionWTxnsQueryHookResult = ReturnType<typeof useGetSubscriptionWTxnsQuery>;
export type GetSubscriptionWTxnsLazyQueryHookResult = ReturnType<typeof useGetSubscriptionWTxnsLazyQuery>;
export type GetSubscriptionWTxnsQueryResult = Apollo.QueryResult<GetSubscriptionWTxnsQuery, GetSubscriptionWTxnsQueryVariables>;
export const IsLoggedInDocument = gql`
    query IsLoggedIn {
  isLoggedIn
}
    `;

/**
 * __useIsLoggedInQuery__
 *
 * To run a query within a React component, call `useIsLoggedInQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsLoggedInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsLoggedInQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsLoggedInQuery(baseOptions?: Apollo.QueryHookOptions<IsLoggedInQuery, IsLoggedInQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsLoggedInQuery, IsLoggedInQueryVariables>(IsLoggedInDocument, options);
      }
export function useIsLoggedInLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsLoggedInQuery, IsLoggedInQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsLoggedInQuery, IsLoggedInQueryVariables>(IsLoggedInDocument, options);
        }
export type IsLoggedInQueryHookResult = ReturnType<typeof useIsLoggedInQuery>;
export type IsLoggedInLazyQueryHookResult = ReturnType<typeof useIsLoggedInLazyQuery>;
export type IsLoggedInQueryResult = Apollo.QueryResult<IsLoggedInQuery, IsLoggedInQueryVariables>;